import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import AddImageModal from "../../../Pages/listings/AddImageModal";
import ListingDataGrid from "../ListingDataGrid";
import AddDocumentModal from "../../../Pages/listings/AddDocumentModal";
const AddListingMeta = ({
  data,
  setData,
  loading,
  setLoading,
  listingIds,
  setListingIDs,
  handleNext,
  FetchData,
  edit,
  handleClose,
  fetchSingleListing,
  listData,
  FetchListings,
  listingData,
  setListingData,
  allImages,
  setAllImages,
  allDocs,
  setAllDocs,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  const [btnLoading, setBtnLoading] = useState(false);
  const [listings, setAllListings] = useState([]);
  const [listingLoading, setListingLoading] = useState(false);
  // const [allImages, setAllImages] = useState([]);
  const [listingMeta, setListingMeta] = useState({
    new_listing_id: edit ? listData?.id : listingIds?.new_listing_id,
    long_description: listData?.meta_tags_for_listings?.long_description || "",
    year_build_in: listData?.meta_tags_for_listings?.year_build_in || "",
    promo_video: listData?.meta_tags_for_listings?.promo_video || "",
    is_featured: listData?.meta_tags_for_listings?.is_featured || 0,
    meta_title: listData?.meta_tags_for_listings?.meta_title || "",
    meta_keywords: listData?.meta_tags_for_listings?.meta_keywords || "",
    meta_description:
      listData?.meta_tags_for_listings?.meta_description ||
      listingIds?.meta_description,
    banner: listData?.meta_tags_for_listings?.banner || "",
    additional_gallery:
      listData?.meta_tags_for_listings?.additional_gallery || [],
  });

  const [bannerPreview, setBannerPreview] = useState(null);

  console.log("banner preview:: ", bannerPreview);

  console.log("allimages: ", allImages);
  console.log("listingMeta: ", listingMeta);

  const [selectImagesModal, setSelectImagesModal] = useState({
    isOpen: false,
    gallery: null,
  });
  const [selectDocModal, setSelectDocModal] = useState({
    isOpen: false,
    docs: null,
  });

  const handleChange = (e) => {
    console.log("chekcbox:::", e.target.value);

    setListingMeta((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCheckboxChange = (event) => {
    console.log("checkbox: ", event.target.checked);
    setListingMeta({
      ...listingMeta,
      is_featured: event.target.checked ? 1 : 0,
    });
  };

  const AddListMeta = () => {
    setBtnLoading(true);

    let listingMetaData;
    if (edit) {
      listingMetaData = {
        new_listing_id: edit ? listData?.id : listingIds?.new_listing_id,
        long_description: listingMeta?.long_description,
        year_build_in: listingMeta?.year_build_in,
        is_featured: listingMeta?.is_featured,
        meta_title: listingMeta?.meta_title,
        meta_keywords: listingMeta?.meta_keywords,
        meta_description: listingMeta?.meta_description,
      };
    } else {
      listingMetaData = listingData;

      if (allImages?.length > 0) {
        const img_name = allImages?.map((img, index) => ({
          [`img_name[]`]: img,
        }));

        listingMetaData = {
          ...listingData,
          img_name,
        };
      }

      if (allDocs?.length > 0) {
        const doc_name = allDocs?.map((doc, index) => ({
          [`doc_name[]`]: doc,
        }));

        listingMetaData = {
          ...listingData,
          doc_name,
        };
      }
    }

    console.log("sending meta data:: ", listingMetaData);

    let url = edit
      ? `${BACKEND_URL}/listings/${listData?.meta_tags_for_listings?.id}`
      : `${BACKEND_URL}/listings`;

    axios
      .post(url, listingMetaData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("listing  added : ", result);
        setBtnLoading(false);

        toast.success(`Listing  ${edit ? "updated" : "added"} successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        if (edit) {
          handleClose();
          fetchSingleListing();
          return;
        }

        setListingMeta({
          long_description: "",
          year_build_in: "",
          promo_video: "",
          is_featured: "",
          meta_title: "",
          meta_keywords: "",
          meta_description: "",

          banner: "",
          additional_gallery: [],
        });
        // FetchData();
        FetchListings(1);
        handleNext();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.data;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  // useEffect(() => {
  //   fetchListings();
  // }, []);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_listing_meta_tag")}
      </h4>

      <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          {/* <Select
            id="new_listing_id"
            value={{
              value: listingMeta?.new_listing_id,
              label: listingMeta?.new_listing_id
                ? listings?.find(
                    (list) => list.id === listingMeta?.new_listing_id
                  )?.title || ""
                : t("menu_listings"),
            }}
            onChange={(e) => {
              setListingMeta({
                ...listingMeta,
                new_listing_id: e.value,
              });
            }}
            options={listings?.map((list) => ({
              value: list.id,
              label: list.title,
            }))}
            isLoading={listingLoading}
            className="w-full"
            placeholder={t("menu_listings")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          /> */}
          {/* <TextField
            id="long_description"
            type={"text"}
            label={t("description")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingMeta?.long_description}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}

          {/* {edit ? null : (
            <>
              <input
                accept="video/*"
                style={{ display: "none" }}
                id="promo-video-file"
                type="file"
                name="video"
                multiple
                onChange={(e) => {
                  console.log("event of video file: ", e);

                  setListingMeta({
                    ...listingMeta,
                    promo_video: e.target.files[0],
                  });
                }}
              />
              <label htmlFor="promo-video-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold my-3"
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    marginBottom: "20px",
                  }}
                  component="span"
                >
                  <span>{t("label_promo_video")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {listingMeta?.promo_video ? `Promo video selected.` : null}
                </p>
              </label>
            </>
          )} */}

          {/* <TextField
            id="meta_title"
            type={"text"}
            label={t("label_meta_title")}
            className="w-full"
            sx={{
              marginBottom: "10px !important",
            }}
            variant="outlined"
            size="small"
            value={listingMeta?.meta_title}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}
          {/* <TextField
            id="canonical"
            type={"text"}
            label={t("label_cano")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingMeta?.canonical}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}
          {/* 
          {edit ? null : (
            <>
              {bannerPreview ? (
                <div className="w-max rounded-lg border">
                  <img
                    src={bannerPreview}
                    width="100px"
                    height="100px"
                    alt="Banner Preview"
                  />
                </div>
              ) : null}

              <input
                accept="image/*"
                style={{ display: "none" }}
                id="banner-image-file"
                type="file"
                name="picture"
                onChange={(e) => {
                  console.log("event of banner image: ", e);

                  const image = e.target.files[0]; // Single file selection
                  const reader = new FileReader();

                  reader.onload = () => {
                    setBannerPreview(reader.result); // Set the preview URL for the single image
                    setListingMeta({
                      ...listingMeta,
                      banner: image, // Store the selected image in the state
                    });
                  };

                  if (image) {
                    reader.readAsDataURL(image); // Read the image file and trigger the onload event
                  }
                }}
              />

              <label htmlFor="banner-image-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold my-3"
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    marginTop: "10px",
                  }}
                  component="span"
                >
                  <span>{t("label_banner")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {listingMeta?.banner ? `Banner image selected.` : null}
                </p>
              </label>
            </>
          )} */}
          {edit ? null : (
            <>
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary  text-white rounded-lg py-3 border-primary font-semibold my-3 "
                  onClick={() =>
                    setSelectImagesModal({
                      isOpen: true,
                      gallery: true,
                    })
                  }
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    marginTop: "20px",
                  }}
                  component="span"
                  // disabled={loading ? true : false}
                  // startIcon={loading ? null : <MdFileUpload />}
                >
                  <span>{t("label_additional_images")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {allImages?.length > 0
                    ? `${allImages?.length} images selected.`
                    : null}
                </p>
              </label>
            </>
          )}
        </Box>
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          {/* <TextField
            id="meta_description"
            type={"text"}
            label={t("label_meta_desc")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingMeta?.meta_description}
            name="area"
            onChange={handleChange}
            required
          /> */}
          {/* <TextField
            id="meta_keywords"
            type={"text"}
            label={t("label_meta_keywords")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingMeta?.meta_keywords}
            name="area"
            onChange={handleChange}
            required
          /> */}

          {/* <FormControlLabel
            control={
              <Checkbox
                value={listingMeta?.is_featured}
                onChange={handleCheckboxChange}
                checked={listingMeta?.is_featured}
                name="permissionCheckbox"
                id="is_featured"
                fullWidth
                inputProps={{ "aria-label": "controlled" }}
                style={{
                  color: currentMode === "dark" ? "#fff" : "#000",
                }}
              />
            }
            label={t("label_is_featured")}
          /> */}
          {/* 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("label_built_year")}
              value={listingMeta?.year_build_in}
              views={["year"]}
              onChange={(newValue) => {
                console.log("meeting date: ", newValue);

                const formattedDate = moment(newValue?.$d).format("YYYY");

                setListingMeta({
                  ...listingMeta,
                  year_build_in: formattedDate,
                });
              }}
              format="YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  readOnly={true}
                  fullWidth
                  size="small"
                  sx={{
                    marginTop: "20px !important",
                  }}
                />
              )}
              // minDate={dayjs().startOf("day").toDate()}
              InputProps={{ required: true }}
            />
          </LocalizationProvider> */}

          {edit ? null : (
            <>
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary  text-white rounded-lg py-3 border-primary font-semibold my-3 "
                  onClick={() =>
                    setSelectDocModal({
                      isOpen: true,
                      doc: true,
                    })
                  }
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    marginTop: "20px",
                  }}
                  component="span"
                  // disabled={loading ? true : false}
                  // startIcon={loading ? null : <MdFileUpload />}
                >
                  <span>{t("additional_files")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {allDocs?.length > 0
                    ? `${allDocs?.length} documents selected.`
                    : null}
                </p>
              </label>
            </>
          )}
        </Box>
        {selectImagesModal?.isOpen && (
          <AddImageModal
            selectImagesModal={selectImagesModal}
            handleClose={() => setSelectImagesModal({ isOpen: false })}
            allImages={allImages}
            setAllImages={setAllImages}
          />
        )}
        {selectDocModal?.isOpen && (
          <AddDocumentModal
            handleClose={() => setSelectDocModal({ isOpen: false })}
            documentModal={selectDocModal?.isOpen}
            allDocs={allDocs}
            setAllDocs={setAllDocs}
          />
        )}
      </div>
      <Button
        className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        sx={{ marginTop: "20px" }}
        onClick={AddListMeta}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button>
    </div>
  );
};

export default AddListingMeta;
